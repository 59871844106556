<template>
  <mapLayer></mapLayer>
  <!-- <div style="width: 100vw; height: 100vh; background-color: aliceblue">
    <areaGroupRename></areaGroupRename>
  </div> -->
</template>

<script>
import mapLayer from "@/components/mapLayer";
import areaGroupRename from "@/components/draggable/areaGroupRename";
export default {
  name: "test",
  components: {
    mapLayer, // 注册 TopSection 组件
    areaGroupRename,
  },
  mounted() {},
};
</script>

<style scoped>
#draggable {
  background: #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 200px;
  height: 200px;
  font-size: 2rem;
}
</style>
