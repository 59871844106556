<template>
  <div>
    <el-button @click="drawRectangle">绘制矩形</el-button>
    <el-button @click="clearRectangle">清除矩形</el-button>
    <el-button @click="drawPolygon">绘制多边形</el-button>
    <el-button @click="drawCircle">绘制圆形</el-button>
    <div ref="map" class="map"></div>
  </div>
</template>

<script>
import searchFrame from "@/components/searchComponents/searchFrame";
import "ol/ol.css";
import { Map, View } from "ol";
import TileLayer from "ol/layer/Tile";
import XYZ from "ol/source/XYZ";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

import { Vector as VectorLayer } from "ol/layer";
import { Vector as VectorSource } from "ol/source";
import { Point, LineString } from "ol/geom";
import { Style, Stroke, Circle, Fill, Icon } from "ol/style";
import Feature from "ol/Feature";
import { fromLonLat } from "ol/proj";

export default {
  name: "mapLayer",
  data() {
    return {
      map: null,
      trackData: [
        { latitude: 48.8566, longitude: 2.3522 }, // 巴黎
        { latitude: 51.5074, longitude: -0.1278 }, // 伦敦
        { latitude: 40.7128, longitude: -74.006 }, // 纽约
        { latitude: 35.6895, longitude: 139.6917 }, // 东京
        { latitude: -33.8688, longitude: 151.2093 }, // 悉尼
      ],
      rectangle: null,
      tmprec: null,
      latlngs: [],
      points:[], geometry:[],lines:"",tempLines:"",node:"",
      r:null,
        i:null,
		tempCircle:null,
		pop:null
    };
  },
  mounted() {
    this.initMap();
    // this.addTrack();
  },
  components: {
    searchFrame,
  },
  methods: {

    initMap() {
      // Leaflet 地图初始化
      this.map = L.map(this.$refs.map, {
        attributionControl: false,
        zoomControl: false, // 禁用放大缩小控件
        maxBounds: [
          [-90, -Infinity], // 南极和无限制的西经度
          [90, Infinity], // 北极和无限制的东经度
        ],
        minZoom: 2,
      }).setView([0, 0], 2);

      // 使用 Leaflet 加载 Mapbox 地图
      L.tileLayer(
        `https://api.mapbox.com/styles/v1/ohx/clil33vcn00g501pf022z1cme/tiles/{z}/{x}/{y}?access_token=pk.eyJ1Ijoib2h4IiwiYSI6ImNsaWwybmV1ODAxdzYzcms5Z3pvNzhvdTIifQ.YpFzvJXs5wJn5JVQcOc3cg`,
        {
          maxZoom: 18,
          tileSize: 512,
          zoomOffset: -1,
        }
      ).addTo(this.map);

      
      //   this.map.on("mouseup", this.onDoubleClick);
    },
    drawRectangle(){
        this.clearRectangle()
        this.map.on("mousedown", this.onClickRectangle); //点击地图
    },
    clearRectangle(){
        if(this.rectangle){
            this.rectangle.remove()
        }
        
        this.rectangle = null
        this.map.off("mousedown")
    },

    //map.off(....) 关闭该事件
    onClickRectangle(e) {
      if (typeof this.tmprec != "undefined" && this.tmprec != null) {
        this.tmprec.remove();
        this.tmprec = null;
        this.map.off("mousemove");
        //右下角坐标
        // let lat = e.latlng.lat > this.points[0][0] + 0.4 ? this.points[0][0] + 0.4 : e.latlng.lat;
        // let lng = e.latlng.lng > this.points[0][1] + 0.4 ? this.points[0][1] + 0.4 : e.latlng.lng;

        // lat = lat < this.points[0][0] - 0.4 ? this.points[0][0] - 0.4 : lat;
        // lng = lng < this.points[0][1] - 0.4 ? this.points[0][1] - 0.4 : lng;
        // this.points[1] = [lat, lng];
        // this.points[1] = ;
        this.rectangle = L.rectangle(this.points, {
          //   color: "#3300ff",
          //   fillOpacity: 0,
          //   weight: 2,
        });
        this.rectangle.addTo(this.map);
        this.map.off("mousedown", this.onClickRectangle);
      } else {
        this.points[0] = [e.latlng.lat, e.latlng.lng];
        
        //开始绘制，监听鼠标移动事件
        this.map.on("mousemove", this.onMoveRectangle);
      }
      //左上角坐标
    },
    onMoveRectangle(e) {
        let lat = e.latlng.lat > this.points[0][0] + 0.4 ? this.points[0][0] + 0.4 : e.latlng.lat;
        let lng = e.latlng.lng > this.points[0][1] + 0.4 ? this.points[0][1] + 0.4 : e.latlng.lng;

        lat = lat < this.points[0][0] - 0.4 ? this.points[0][0] - 0.4 : lat;
        lng = lng < this.points[0][1] - 0.4 ? this.points[0][1] - 0.4 : lng;
      this.points[1] = [lat, lng];
      //删除临时矩形
      console.log(this.points);
      if (typeof this.tmprec != "undefined" && this.tmprec != null) {
        this.tmprec.remove();
      }
      //添加临时矩形
      this.tmprec = L.rectangle(this.points, { dashArray: 5 }).addTo(this.map);
    },

    onDoubleClick(e) {
      //矩形绘制完成，移除临时矩形，并停止监听鼠标移动事件
      this.tmprect.remove();
      this.map.off("mousemove");
      //右下角坐标
      this.points[1] = [e.latlng.lat, e.latlng.lng];
      this.rectangle = L.rectangle(this.points, {
        color: "#3300ff",
        fillOpacity: 0,
        weight: 2,
      });
      this.rectangle.addTo(this.map);
      //调整view范围

      //   this.map.fitBounds(this.rectangle);
    },




    drawPolygon(){
			if(this.tempLines){
				this.removePolygon();
			}
			this.map.doubleClickZoom.disable();
			this.lines = new L.polyline([]);
			this.tempLines = new L.polyline([],{ dashArray: 5 });
			this.points = [];
			this.geometry = [];

			this.map.on('click', onClickPolygon);    //点击地图
			this.map.on('dblclick', onDoubleClickPolygon);
			this.map.on('mousemove', onMovePolygon)//双击地图
			let that = this
			function onClickPolygon(e) {
                console.log(that.points)
                // that.points = []
			    that.points.push([e.latlng.lat, e.latlng.lng])
			    that.lines.addLatLng(e.latlng)
			    that.map.addLayer(that.tempLines)
			    that.map.addLayer(that.lines)
			    that.node=L.circle(e.latlng, { color: '#ff0000', fillColor: 'ff0000', fillOpacity: 1 })
			    that.map.addLayer(that.node)
			    that.geometry.push(that.node)
			}
			function onMovePolygon(e) {
			    if (that.points.length > 0) {
			        let ls = [that.points[that.points.length - 1], [e.latlng.lat, e.latlng.lng], that.points[0]]
			        that.tempLines.setLatLngs(ls)
			        that.map.addLayer(that.tempLines)
			    }
			}
			function onDoubleClickPolygon(e) {
			    that.geometry.push(L.polygon(that.points).addTo(that.map))
			    that.points = [];
				that.node=null;
				that.map.off('click', onClickPolygon);    //点击地图
				that.map.off('dblclick', onDoubleClickPolygon);
				that.map.off('mousemove', onMovePolygon)//双击地图
				that.map.doubleClickZoom.enable();
				//isInPolygon(marker);
			}
		},

		removePolygon(){
			if(this.geometry){
				for(let ooo of this.geometry){
					ooo.remove();
				}
				this.map.removeLayer(this.lines);
				this.map.removeLayer(this.tempLines);
			}
		},


		removeCircle(){
			this.map.removeLayer(this.tempCircle);
		},
		//绘制圆
		drawCircle(){
		//在绘制圆之前需要先判断是否已经绘制过了，如果有，则清空再绘制
		//如果需要绘制多个圆，则不必此句
			if(this.tempCircle){
				this.removeAll();
			}
			this.r = 0;
			this.i = null;
			this.tempCircle = new L.circle();
			this.map.dragging.disable();//将mousemove事件移动地图禁用
			//监听鼠标落下事件
			this.map.on('mousedown', this.onmouseDownCircle);
			
			
		},
        onmouseDownCircle(e) {
				//确定圆心
			    this.i = e.latlng
                console.log(e.latlng)
				this.pop=L.popup().setLatLng(e.latlng);
                console.log(this.pop)
				this.map.addLayer(this.pop);
				//监听鼠标移动事件
				this.map.on('mousemove', this.onMoveCircle);
				//监听鼠标弹起事件
				this.map.on('mouseup', this.onmouseUpCircle);
			},
		onMoveCircle(e) {
				this.r = L.latLng(e.latlng).distanceTo(this.i);//计算半径
				if(this.r<500000){
					if (this.i) {
						//绘制圆心位置与半径
					    this.tempCircle.setLatLng(this.i)
					    this.tempCircle.setRadius(this.r)
					    this.tempCircle.setStyle({ color: '#ff0000',  fillOpacity: 0 })
					    this.map.addLayer(this.tempCircle)
					}
					//toFixed()方法用来保留两位小数（四舍五入）
					this.pop.setContent("绘制圆半径为："+this.r.toFixed(2)+"米");;
				}else{
					this.r=500000;
					if (this.i) {
					    this.tempCircle.setLatLng(this.i)
					    this.tempCircle.setRadius(this.r)
					    this.tempCircle.setStyle({ color: '#ff0000',  fillOpacity: 0 })
					    this.map.addLayer(this.tempCircle)
					}
					this.pop.setContent("绘制圆半径为："+this.r+"米");;
				}
			},
		onmouseUpCircle(e) {
			    /* r = L.latLng(e.latlng).distanceTo(i); */
				this.map.removeLayer(this.pop);
				L.circle(this.i, { radius: this.r, color: '#ff0000',  fillOpacity: 0 });
				this.map.addLayer(this.tempCircle);
				this.map.dragging.enable();
				
			
				
				this.i = null;
				this.r = 0;
			   //取消监听事件
			   this.map.off('mousedown');
			   this.map.off('mouseup');
			   this.map.off('mousemove');

			},
            removeAll() {
			// this.removePolygon()
			this.removeCircle()
            }
					







  },
};
</script>

<style scoped>
.map {
  height: 93.6vh;
  width: 98.7vw;
}
</style>
